import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ sx }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.common.white;
  const PRIMARY_DARK = theme.palette.primary.main;

  return (
    <Box
      ref={ref}
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg
        preserveAspectRatio="xMidYMid meet"
        x="0"
        y="0"
        viewBox="0 0 700 100"
        width="150"
        height="64"
      >
        <g>
          <g>
            <path
              d="M650.6,99.9h-6v-2.1h14.5v2.1h-6v17.4h-2.5L650.6,99.9z"
              fill={PRIMARY_DARK}
            />
            <path
              d="M677.8,108.7c-0.2-2.7-0.3-6-0.3-8.4h-0.1c-0.7,2.3-1.5,4.7-2.5,7.4l-3.5,9.5h-1.9l-3.2-9.3c-0.9-2.8-1.7-5.3-2.3-7.6h-0.1
		c-0.1,2.4-0.2,5.7-0.4,8.6l-0.5,8.4h-2.4l1.4-19.6h3.2l3.3,9.5c0.8,2.4,1.5,4.5,2,6.6h0.1c0.5-2,1.2-4.1,2.1-6.6l3.5-9.5h3.2
		l1.2,19.6h-2.5L677.8,108.7z"
              fill={PRIMARY_DARK}
            />
          </g>
          <g>
            <path
              d="M71.7,84.9c0,4.7-0.9,9.3-2.7,13.5c-1.8,4.2-4.4,7.9-7.8,11c-3.6,3.2-7.7,5.7-12.3,7.4c-5.3,1.9-10.8,2.8-16.4,2.7
		c-5.7,0-11.3-0.7-16.9-2c-5.4-1.2-10.7-3.2-15.7-5.8l5.1-20.5c3.9,2,7.9,3.6,12.1,4.9c4.7,1.4,9.6,2.1,14.6,2.1
		c3.7,0.2,7.4-0.7,10.6-2.5c2.8-1.7,4.1-4.5,4.1-8.3c-0.1-2.9-1.4-5.6-3.6-7.5c-2.4-2.2-6.8-4.6-13.1-7.1c-10-4-17.3-8.7-22-13.9
		s-7-11.9-6.9-20c0-10.6,3.2-18.7,9.7-24.3c6.5-5.6,15.5-8.4,27-8.4c5.6,0,11.3,0.7,16.7,2.1c4.7,1.1,9.3,2.9,13.5,5.3l-4.3,20.2
		c-3.7-1.9-7.7-3.4-11.7-4.5c-4.3-1.2-8.7-1.7-13.1-1.7c-3.2-0.1-6.3,0.6-9.1,2.1c-2.3,1.4-3.4,3.7-3.4,7c0,1.3,0.2,2.7,0.6,3.9
		c0.5,1.2,1.2,2.3,2.2,3.1c1.4,1.2,2.9,2.2,4.5,3c1.9,1,4.4,2.1,7.4,3.5C45.1,52,49.2,54,52.9,56c3.6,1.9,6.9,4.4,9.8,7.2
		c2.7,2.7,4.9,5.9,6.5,9.4C70.9,76.5,71.8,80.7,71.7,84.9z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M122.6,109.7c-2.8,2.8-6,5.1-9.6,6.6c-4.2,1.9-8.7,2.9-13.3,2.9c-3.1,0-6.2-0.6-9-1.8c-2.6-1.1-4.8-2.8-6.7-4.9
		c-1.8-2.1-3.3-4.6-4.2-7.3c-1-2.9-1.5-5.9-1.5-9c0-7.4,2.2-13.1,6.5-16.8c4.3-3.8,10.5-6,18.5-6.6c3-0.2,6.1-0.4,9.4-0.5
		s6.3-0.2,8.8-0.3v-2.6c0-8.8-4.6-13.1-13.6-13.1c-3.5,0-7,0.4-10.4,1.3c-3.2,0.8-6.2,2-9.1,3.6l-4.5-18.3c4-2.4,8.2-4.1,12.7-5.3
		c5.2-1.4,10.5-2.1,15.9-2c10.5,0,18.8,2.9,24.7,8.8c5.9,5.9,8.9,14.6,8.9,26.4v47h-23.6V109.7z M109.2,102c2.3,0,4.5-0.3,6.7-1
		c2-0.6,4-1.5,5.8-2.6v-12c-2.5,0.2-4.7,0.5-6.6,0.7c-1.9,0.3-3.8,0.5-5.7,0.7c-5,0.7-7.5,3.2-7.5,7.6c-0.1,1.8,0.6,3.5,1.9,4.8
		C105.3,101.5,107.3,102.1,109.2,102z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M164.6,117.6V57.2H153v-20h11.7v-2.3c0-11.9,2.9-20.7,8.7-26.4c5.8-5.6,13.5-8.5,23.2-8.5c2.5,0,5.1,0.2,7.6,0.6
		c2.5,0.3,5,1,7.3,2l-1.8,18.6c-2.6-0.8-5.3-1.2-8-1.1c-3.9,0-6.9,1-9.1,3s-3.3,5.5-3.3,10.6v3.5h17.2v20h-17.2v60.4L164.6,117.6z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M245.8,35.5c9.8,0,17.6,3.1,23.3,9.3c5.7,6.2,8.6,14.8,8.6,25.7c0,4.5-0.5,8.9-1.4,13.3h-43.6c0.2,4.9,1.8,8.9,4.7,11.9
		c2.9,3,7.4,4.5,13.4,4.6c3.9,0,7.8-0.4,11.6-1.1c3.6-0.6,7.2-1.7,10.5-3.2l3.1,16.9c-4.1,2.2-8.6,3.8-13.1,4.8
		c-5,1.1-10,1.7-15.1,1.6c-5.9,0.1-11.8-0.9-17.3-3c-4.8-1.9-9.1-4.7-12.6-8.5c-3.4-3.7-6.1-8.2-7.7-13c-1.8-5.4-2.7-11.1-2.6-16.8
		c-0.1-6,0.8-12,2.7-17.8c1.6-4.9,4.2-9.5,7.7-13.4c3.4-3.7,7.5-6.5,12.1-8.4C235.1,36.4,240.4,35.4,245.8,35.5z M245.1,52.2
		c-3.9,0-6.9,1.3-9,4s-3.1,6.4-3.1,11.1h21.8c0.2-0.9,0.3-1.9,0.3-2.8c0.1-3.1-0.8-6.2-2.6-8.8C250.7,53.4,247.9,52.1,245.1,52.2z"
              fill={PRIMARY_MAIN}
            />
            <path
              d="M362.8,43.8c0.1,5.4-1.1,10.8-3.5,15.6c-2.3,4.6-5.7,8.5-9.8,11.6c-4.4,3.3-9.4,5.7-14.6,7.1
		c-5.9,1.7-12.1,2.5-18.3,2.5H314v37h-25.2V8.2h29.5c6,0,12,0.7,17.9,2.1c5.1,1.2,9.9,3.4,14.1,6.5c3.9,2.9,7.1,6.7,9.2,11.1
		C361.8,32.9,362.9,38.4,362.8,43.8z M338.5,44c0-10.1-6.7-15.1-20.1-15.1h-4.5v31h4.1c6.7,0,11.7-1.4,15.2-4.3
		C336.7,52.8,338.7,48.5,338.5,44z"
              fill={PRIMARY_DARK}
            />
            <path
              d="M445,77.4c0,5.6-0.8,11.2-2.6,16.5c-1.6,4.9-4.2,9.4-7.7,13.3c-3.5,3.9-7.8,6.9-12.6,8.9
		c-11.1,4.3-23.3,4.3-34.4,0c-4.8-2-9.1-5-12.6-8.9c-3.4-3.9-6-8.4-7.7-13.3c-3.5-10.7-3.5-22.3,0-33c1.6-4.9,4.2-9.4,7.7-13.3
		c3.5-3.9,7.8-6.9,12.6-8.9c11.1-4.3,23.3-4.3,34.4,0c4.8,2,9.1,5,12.6,8.9c3.4,3.9,6,8.4,7.7,13.3C444.2,66.2,445,71.8,445,77.4z
		 M420.4,77.4c0-2.8-0.3-5.6-0.8-8.3c-0.5-2.5-1.3-5-2.5-7.2c-3.7-6.7-12.1-9.2-18.8-5.6c-2.4,1.3-4.3,3.2-5.6,5.6
		c-1.2,2.3-2.1,4.7-2.5,7.2c-1,5.5-1,11.1,0,16.6c0.5,2.5,1.3,5,2.5,7.2c3.7,6.7,12.1,9.2,18.8,5.6c2.4-1.3,4.3-3.2,5.6-5.6
		c1.2-2.3,2.1-4.7,2.5-7.2C420.2,82.9,420.4,80.1,420.4,77.4z"
              fill={PRIMARY_DARK}
            />
            <path
              d="M454.5,117.6V37.1h24.6v80.5L454.5,117.6z"
              fill={PRIMARY_DARK}
            />
            <path
              d="M493.8,117.6V37.1h23.6v10.5c2-4,5.2-7.3,9.1-9.4c3.7-1.8,7.8-2.8,11.9-2.8c4.5-0.1,8.9,1,12.7,3.4
		c3.5,2.3,6.4,5.3,8.5,9c2.3,4,3.9,8.4,4.8,12.9c1,5.1,1.5,10.2,1.5,15.4v41.6h-24.6V75.6c0-6.7-1-11.6-2.9-14.7s-4.9-4.7-8.9-4.7
		c-2.4-0.1-4.7,0.6-6.7,1.8c-1.8,1.1-3.4,2.6-4.5,4.4v55.2H493.8z"
              fill={PRIMARY_DARK}
            />
            <path
              d="M571.1,37.1h11.5v-21h24.6v21h21.6v20h-21.6v31.4c-0.1,2.6,0.5,5.2,1.9,7.4c1.3,1.8,3.6,2.7,7,2.7
		c1.9,0,3.7-0.1,5.6-0.4c1.8-0.3,3.7-0.7,5.4-1.2l1.7,18.4c-2.9,1.3-5.9,2.3-9,2.8c-3.5,0.7-7.1,1-10.8,1c-4.2,0.1-8.4-0.7-12.2-2.4
		c-3.2-1.5-6-3.7-8.1-6.4c-2.2-2.8-3.7-6.1-4.6-9.5c-1-3.8-1.5-7.8-1.5-11.8v-32h-11.5L571.1,37.1z"
              fill={PRIMARY_DARK}
            />
          </g>
          <circle cx="467.3" cy="15.1" r="12.8" fill={PRIMARY_DARK} />
        </g>
      </svg>
    </Box>
  );
});

Logo.propTypes = {
  sx: PropTypes.object
};

export default Logo;
