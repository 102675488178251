import { Box, Container, Typography } from '@material-ui/core';

export default function HeadlineBanner(props) {
  const { title, subtitle, bgColor, color } = props;

  return (
    <Box
      bgcolor={bgColor ? bgColor : 'primary.main'}
      sx={
        {
          // backgroundImage: bgColor
          //   ? 'inherit'
          //   : `linear-gradient(0deg, rgba(223, 227, 232, 1) 0%, rgba(255, 255, 255, 0) 10%)`
        }
      }
    >
      <Container maxWidth={'lg'}>
        <Box
          justifyContent={'center'}
          alignContent={'center'}
          pt={8}
          pb={8}
          pl={3}
          pr={3}
        >
          <Typography
            // sx={{ fontSize:  }}
            component={'h2'}
            color={color ? color : 'secondary.main'}
            variant={'h2'}
            textAlign={'center'}
          >
            {title}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
