// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PUBLIC = '/';

// ----------------------------------------------------------------------
// export const PATH_PAGE = {
//   comingSoon: '/coming-soon',
//   maintenance: '/maintenance',
//   pricing: '/pricing',
//   payment: '/payment',
//   about: '/about-us',
//   contact: '/contact-us',
//   faqs: '/faqs',
//   page404: '/404',
//   page500: '/500',
//   components: '/components'
// };

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/one'),
    pageTwo: path(ROOTS_DASHBOARD, '/two'),
    pageThree: path(ROOTS_DASHBOARD, '/three')
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/app'),
    pageFour: path(ROOTS_DASHBOARD, '/app/four'),
    pageFive: path(ROOTS_DASHBOARD, '/app/five'),
    pageSix: path(ROOTS_DASHBOARD, '/app/six')
  }
};

export const PATH_PUBLIC = {
  root: ROOTS_PUBLIC,
  public: {
    community: path(ROOTS_PUBLIC, '/community'),
    about: path(ROOTS_PUBLIC, '/about'),
    requestDemo: path(ROOTS_PUBLIC, '/request-demo'),
    security: path(ROOTS_PUBLIC, '/security'),
    communication: path(ROOTS_PUBLIC, '/communication'),
    features: path(ROOTS_PUBLIC, '/features'),
    faq: path(ROOTS_PUBLIC, '/faq')
  }
};
