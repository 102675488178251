import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import FeaturesPage from 'pages/FeaturesPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'community', element: <CommunityPage /> },
        { path: 'features', element: <FeaturesPage /> },
        { path: 'communication', element: <CommunicationPage /> },
        { path: 'security', element: <SecurityPage /> },
        { path: 'features', element: <FeaturesPage /> },
        { path: 'request-demo', element: <RequestDemoForm /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'schedule-demo', element: <ScheduleDemoPage /> },
        { path: 'faq', element: <FaqPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: '/checkout/*',
      element: <AchCheckout />
    },
    {
      path: '/retail/signup',
      element: <AchCheckoutRetail />
    },
  ]);
}

// IMPORT COMPONENTS

// Dashboard

// Main
const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));
const AboutPage = Loadable(lazy(() => import('pages/AboutPage')));
const CommunityPage = Loadable(lazy(() => import('pages/CommunityPage')));
const SecurityPage = Loadable(lazy(() => import('pages/SecurityPage')));
const PrivacyPolicyPage = Loadable(lazy(() => import('pages/PrivacyPolicyPage')));
const FaqPage = Loadable(lazy(() => import('pages/Faqs')));
const CommunicationPage = Loadable(
  lazy(() => import('pages/CommunicationPage'))
);
const ScheduleDemoPage = Loadable(lazy(() => import('pages/ScheduleDemoPage')));

const RequestDemoForm = Loadable(lazy(() => import('pages/RequestDemo')));

const NotFound = Loadable(lazy(() => import('pages/Page404')));
const AchCheckout = Loadable(lazy(() => import('pages/AchCheckout')));
const AchCheckoutRetail = Loadable(lazy(() => import('pages/AchCheckoutRetail')));

