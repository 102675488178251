import mondaySdk from 'monday-sdk-js';

import { MONDAY_TOKEN } from 'utils/constants';
const monday = mondaySdk();
monday.setToken(MONDAY_TOKEN);

const query2 = `query { users { id, name } }`;

export async function submitMondayItem(values) {
  const {
    schoolName,
    yourName,
    jobTitle,
    phone,
    email,
    schoolType,
    message,
    businessAddress,
    lng,
    lat
  } = values;

  const mondayBoardColumns = {
    text: schoolName,
    text8: yourName,
    text7: schoolType,
    text22: jobTitle,
    email: { email: email, text: email },
    phone: { phone, countryShortName: 'US' },
    location8: businessAddress,
    dropdown: 'SafePoint',
    //long_text: { text: message }
  };
  const column_values = JSON.stringify(JSON.stringify(mondayBoardColumns));
  const boardId = 2837196289;

  const query = `mutation {
    create_item(item_name: "${schoolName}", board_id: ${boardId}, column_values: ${column_values}) {
      id
      column_values {
        value
      }
    }
  }`;
  try {
    const response = await monday.api(query);
    console.log('Form Submission Response', '=>', response);
    const itemID = parseInt(response.data.create_item.id);
    if (message.length !== 0) {
      const updateQuery = `mutation {
        create_update (item_id: ${itemID}, body: "<div><b><u>User Message:</u></b></div><br> <p>${message}</p>") {
            id
        }
    }`;
      const updateItem = await monday.api(updateQuery);
      console.log('Form Submission Response', '=>', updateItem);
    }
    return response;
  } catch (error) {}
}

export async function submitMondayACHForm(values) {
  const {
    lodgeName,
    contactName,
    contactPhone,
    contactEmail,
    accountHolderName,
    businessAddress,
    city,
    state,
    zip,
    businessPhone,
    businessEmail,
    dbaName,
    taxId,
    numMembers,
    elecSignature,
    subscription,
    payMethod,
    routingNumber,
    accountNumber,
    frontOfCheck
  } = values;
  const date = new Date();

  const mondayBoardColumns = {
    //text4: lodgeName,
    text5: dbaName,
    text16: contactName,
    text1: { phone: businessPhone, countryShortName: 'US' },
    text51: { email: businessEmail, text: businessEmail },
    text3: { phone: contactPhone, countryShortName: 'US' },
    email: { email: contactEmail, text: contactEmail },
    location3: businessAddress,
    text50: city,
    text1659: state,
    text2: zip,
    text40: taxId,
    text48: numMembers,
    text78: elecSignature,
    text7: subscription,
    text09: payMethod,
    //text80: accountHolderName,
    text0: routingNumber,
    text9: accountNumber,
    date4: date.toISOString().substring(0, 10)
  };

  const column_values = JSON.stringify(JSON.stringify(mondayBoardColumns));
  const boardId = 3092753912;
  const query = `mutation {
    create_item(item_name: "${lodgeName}", board_id: ${boardId}, column_values: ${column_values}) {
      id
    }
  }`;
  try {
    const response = await monday.api(query);
    const item_id = response.data.create_item.id;

    const fileQuery = `mutation {
      add_file_to_column (item_id: ${item_id}, column_id: "files", file: "${frontOfCheck}") {
        id
      }
    }`;

    const fileResponse = await monday.api(fileQuery);
    return response && fileResponse;
  } catch (error) {}
}

