// material
import { styled } from '@material-ui/core/styles';
// components
import Page from '../components/Page';
import {
  FeatureHero,
  FeatureMinimal,
  FeatureDarkMode,
  FeatureThemeColor,
  FeatureAdvertisement,
  FeatureCleanInterfaces,
  FeatureHugePackElements,
  FeatureList
} from '../components/_external-pages/features';
import RequestDemo2 from './RequestDemo';

// Global Components
import HeadlineBanner from 'components/HeadlineBanner';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)({
  height: '100%'
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function FeaturePage() {
  return (
    <RootStyle title="Mobile Apps for Schools | SafePoint" id="move_top">
      <ContentStyle>
        {/* <FeatureHugePackElements /> */}
        <FeatureMinimal />
        <FeatureDarkMode />
        <FeatureList />
        <RequestDemo2 />
        {/* <FeatureThemeColor /> */}
        {/* <FeatureCleanInterfaces /> */}
        {/* <FeatureAdvertisement /> */}
        {/* <FeatureHero /> */}
      </ContentStyle>
    </RootStyle>
  );
}
