export const SCHOOL_TYPES = ['Public', 'Private', 'Charter', 'Other'];
export const MONDAY_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI2MzE3OTQyNywiYWFpIjoxMSwidWlkIjoyNDEyNzA0MCwiaWFkIjoiMjAyMy0wNi0xN1QwMTowMzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTA3NTg1NywicmduIjoidXNlMSJ9.2wRH_eKufYjyjuW67svOthFZ-IF9oPeNS43Ct72Bmvk';

export const SCHOOL_SIZES = ['0-100', '100-500', '1000-2000', '2000+'];

export const STATE_SELECT_OPTIONS = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];
