import { Icon } from '@iconify/react';

import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  IconButton,
  Stack
} from '@material-ui/core';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Learn',
    children: [
      // { name: 'About us', href: '#' },
      { name: 'Community', href: '/community' },
      { name: 'Security', href: '/security' },
      { name: 'Communication', href: '/communication' },
      // { name: 'FAQs', href: '#' }
    ]
  },

  {
    headline: 'Legal',
    children: [
      { name: 'Terms & Conditions', href: '#' },
      { name: 'Privacy Policy', href: '#' }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'info@safepointpro.com', href: 'mailto:info@safepointpro.com?subject=A%20Safepoint%20Mobile%20App%20for%20my%20School' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[900],
  color: '#b3b3b3'
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 3 }}>
        {/* <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth sx={{ width: '100%' }}>
              <img src="/static/Logo-Dark.png" height="30" width="169" />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={4}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Owning your own platform is essential. A Custom Mobile App built
              with the SafePoint Platform will drive more business through
              engaging content and communication.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={10}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline2">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid> */}

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 0,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          SafePoint, LLC © 2022. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
