import { useState } from 'react';

import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import flashFill from '@iconify/icons-eva/flash-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Link,
  Container,
  Typography,
  Stack,
  useMediaQuery
} from '@material-ui/core';
// routes
import { PATH_PUBLIC } from 'routes/paths';
//
import {
  varFadeIn,
  varFadeInUp,
  varWrapEnter,
  varFadeInRight
} from '../../animate';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary,
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center'
  }
}));

const ContentStyle = styled((props) => <Stack spacing={2} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 550,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left'
    }
  })
);

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '10%',
    top: '15%',
    width: 'auto',
    height: '100vh'
  }
}));

// ----------------------------------------------------------------------

export default function FeatureHero() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroOverlayStyle
          alt="overlay"
          src="/static/overlay.svg"
          variants={varFadeIn}
        />

        <HeroImgStyle
          alt="hero"
          src="/static/home/hero2.png"
          variants={varFadeInUp}
        />

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h1" sx={{ color: 'common.white' }}>
                Sign Up Today &amp; Get 12 Months
                <Typography
                  variant="h1"
                  component="span"
                  sx={{
                    lineHeight: 1.5,
                    fontWeight: 800,
                    color: 'primary.main'
                  }}
                >
                  &nbsp;Free
                </Typography>
              </Typography>
            </motion.div>

            <motion.div
              variants={varFadeInRight}
              style={{ marginBottom: 40, maxWidth: 520 }}
            >
              <Typography sx={{ color: 'common.white', fontSize: 20 }}>
                Contact our team today for hands-on guidance tailored to your
                schools’ specific needs.
              </Typography>
            </motion.div>

            <Stack
              component={motion.div}
              variants={varFadeInRight}
              direction={isDesktop ? 'row' : 'column'}
              spacing={3}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              alignItems={{ xs: 'center' }}
            >
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to={PATH_PUBLIC.public.requestDemo}
                startIcon={<Icon icon={flashFill} width={20} height={20} />}
              >
                Schedule a Demo
              </Button>
              <div
                style={{
                  color: '#ebebeb',
                  fontSize: 24,
                  fontWeight: 600
                }}
              >
                (800) 757-8234
              </div>
            </Stack>

            <motion.div variants={varFadeInRight}></motion.div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}
