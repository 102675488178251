import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
//form validation
import { useFormik } from 'formik';
import * as yup from 'yup';
// API
import { submitMondayItem } from 'API/monday';
import { usePlacesWidget } from 'react-google-autocomplete';

// material Ui
import {
  Container,
  FormControl,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Grid,
  TextField,
  Box
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
// Constants
import { STATE_SELECT_OPTIONS, SCHOOL_TYPES } from 'utils/constants';

// ----------------------------------------------------------------------
const PageStyle = styled((props) => <Page {...props} />)(({ theme }) => ({
  overFlow: 'hidden',
  backgroundColor: theme.palette.grey[300],
  backgroundImage: `url(/static/backgrounds/bg_page_3.jpg)`,
  backgroundPosition: '100% 60%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}));

const WaveBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '30%',
  left: 'auto',
  width: '100vw',
  zIndex: 1
}));

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 999,
  marginTop: 72,
  padding: `${theme.spacing(10)} 0`,
  width: '100%',
  [theme.breakpoints.up('md')]: {}
}));

const PaperStyle = styled(Paper)(({ theme }) => ({
  backgroundColor: 'common-white',
  padding: theme.spacing(4),
  // margin: `${theme.spacing(5)} 0`,

  [theme.breakpoints.up('md')]: {
    width: '100%'
    // alignItems: 'flex-start'
  }
}));

const FormFooterStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {}
}));

const ButtonSubmit = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: 300
  }
}));

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  schoolName: yup
    .string('Enter School Name')
    .required('School Name is required'),
  yourName: yup.string('Enter Your Name').required('Your Name is required'),
  phone: yup
    .string('Enter your Phone Number')
    .required('Phone is required')
    .matches(phoneRegExp, 'Please enter a valid phone number'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  businessAddress: yup
    .string('Enter Your State')
    .required('Your State is required')
});

export default function RequestDemo2() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const formik = useFormik({
    initialValues: {
      schoolName: '',
      yourName: '',
      phone: '',
      email: '',
      website: '',
      jobTitle: '',
      message: '',
      businessAddress: '',
      schoolType: '',
      message: '',
      lat: '',
      lng: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const response = await submitMondayItem(values);
      setSubmitting(false);
      setIsSubmitted(true);
      resetForm({
        schoolName: '',
        yourName: '',
        phone: '',
        email: '',
        website: '',
        jobTitle: '',
        message: '',
        schoolState: '',
        schoolType: '',
        message: ''
      });
    }
  });
  const { isSubmitting } = formik;

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyDr8pbevHiJWc_zTdcHzeQkaehgdKYShjc',
    onPlaceSelected: (place) => {
      formik.setFieldValue('businessAddress', place.formatted_address);
      formik.setFieldValue('lng', place.geometry.location.lng());
      formik.setFieldValue('lat', place.geometry.location.lat());
    },

    options: {
      types: ['address']
    }
  });

  // console.log(formState);
  return (
    <PageStyle title="Request a School Demo | SafePoint ">
      {/* <WaveBackground>
        <img src={SvgWaveBackground} />
      </WaveBackground> */}
      <Container maxWidth={'lg'}>
        <RootStyle>
          <Grid container spacing={10} alignContent={'center'}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                component="h1"
                paragraph
                style={{ marginBottom: 20, fontSize: 48, lineHeight: 1.2 }}
              >
                Let's Get in Touch
              </Typography>
              <Typography variant="body1" component="div" sx={{ mb: 4, pr: 9 }}>
                Have questions about SafePoint for your School or want to talk to
                one of our team members to learn more? Fill out this form and
                one of our experts will be in touch as soon as possible.
              </Typography>

              <Typography variant={'body1'} component={'div'} mb={3}>
                You’ll get a look at our:
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 13
                }}
              >
                <CheckCircle color={'success'} style={{ fontSize: 30 }} />
                <div style={{ marginLeft: 20 }}>
                  Complete mobile solution for schools
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 13
                }}
              >
                <CheckCircle
                  color={'success'}
                  style={{ fontSize: 30, color: 'theme' }}
                />
                <div style={{ marginLeft: 20 }}>
                  Easy to use Content Management Console
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 40
                }}
              >
                <CheckCircle color={'success'} style={{ fontSize: 30 }} />
                <div style={{ marginLeft: 20 }}>
                  Help with any questions you have about SafePoint.
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 4 }}>Have a question?</div>

                <RouterLink to="/faq">
                  Read our FAQs.
                </RouterLink>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <PaperStyle>
                {!isSubmitted ? (
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          name="schoolName"
                          fullWidth
                          label="School Name"
                          value={formik.values.schoolName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.schoolName &&
                            Boolean(formik.errors.schoolName)
                          }
                          helperText={
                            formik.touched.schoolName &&
                            formik.errors.schoolName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="yourName"
                          fullWidth
                          label="Your Name"
                          value={formik.values.yourName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.yourName &&
                            Boolean(formik.errors.yourName)
                          }
                          helperText={
                            formik.touched.yourName && formik.errors.yourName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Job Title"
                          name="jobTitle"
                          value={formik.values.jobTitle}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.jobTitle &&
                            Boolean(formik.errors.jobTitle)
                          }
                          helperText={
                            formik.touched.jobTitle && formik.errors.jobTitle
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="phone"
                          fullWidth
                          label="Phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          inputRef={materialRef}
                          id={'businessAddress'}
                          type={'text'}
                          name={'businessAddress'}
                          label="Address"
                          onChange={formik.handleChange}
                          value={formik.values.businessAddress}
                          error={
                            formik.touched.businessAddress &&
                            Boolean(formik.errors.businessAddress)
                          }
                          helperText={
                            formik.touched.businessAddress &&
                            formik.errors.businessAddress
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="school-type-select-label">
                            School Type
                          </InputLabel>
                          <Select
                            labelId="school-type-select-label"
                            id="school-type-select"
                            name="schoolType"
                            label="School Type"
                            value={formik.values.schoolType}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.schoolType &&
                              Boolean(formik.errors.schoolType)
                            }
                            helperText={
                              formik.touched.schoolType &&
                              formik.errors.schoolType
                            }
                          >
                            {SCHOOL_TYPES.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          label="Questions or comments"
                          name="message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.message &&
                            Boolean(formik.errors.message)
                          }
                          helperText={
                            formik.touched.message && formik.errors.message
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12} mt={2} mb={2}>
                        <FormFooterStyle>
                          <ButtonSubmit
                            loading={isSubmitting}
                            type="submit"
                            size="large"
                            variant="contained"
                            xs={{ padding: '15px 80px' }}
                          >
                            Submit
                          </ButtonSubmit>
                        </FormFooterStyle>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Grid
                    container
                    justify={'center'}
                    alignItems={'center'}
                    spacing={3}
                  >
                    <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                      <Typography
                        sx={{ opacity: 0.8 }}
                        gutterBottom
                        variant={'h3'}
                      >
                        Thank you!
                      </Typography>
                      <Typography>
                        Your message was sent successfully.
                      </Typography>

                      <Box sx={{ pt: 4 }}>
                        <img
                          style={{ margin: '0 auto' }}
                          src="/static/success.svg"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </PaperStyle>
            </Grid>
          </Grid>
        </RootStyle>
      </Container>
    </PageStyle>
  );
}
