// material
import { Container, Typography, Grid, Paper, Avatar } from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core';
import {
  EventTwoTone,
  MarkChatUnreadTwoTone,
  ManageAccountsTwoTone,
  NotificationsActiveTwoTone,
  CampaignTwoTone,
  ConnectWithoutContactTwoTone,
  IntegrationInstructionsTwoTone,
  FeedTwoTone
} from '@material-ui/icons';

// ----------------------------------------------------------------------
const CARDS = [
  {
    icon: <EventTwoTone style={{ fontSize: 40, color: '#4e4e4e' }} />,
    title: 'Schedules',
    description: 'Updated events, sports calendars and school schedules.'
  },
  {
    icon: <MarkChatUnreadTwoTone style={{ fontSize: 40, color: '#4e4e4e' }} />,
    title: 'Push Notifications',
    description:
      'Send instant notifications to all app users at the touch of a button.'
  },
  {
    icon: <CampaignTwoTone style={{ fontSize: 40, color: '#4e4e4e' }} />,
    title: 'Announcements',
    description: 'Showcase daily and important announcements.'
  },
  {
    icon: <FeedTwoTone style={{ fontSize: 40, color: '#4e4e4e' }} />,
    title: 'News',
    description: 'Keep the community up to date with the latest news.'
  },
  {
    icon: (
      <IntegrationInstructionsTwoTone
        style={{ fontSize: 40, color: '#4e4e4e' }}
      />
    ),
    title: 'Website Integration',
    description:
      'All information on your website can be found easily on the app.'
  },
  {
    icon: (
      <ConnectWithoutContactTwoTone
        style={{ fontSize: 40, color: '#4e4e4e' }}
      />
    ),
    title: 'User Engagement',
    description:
      'All users have the option to engage with interactive posts and questionnaires.'
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(20),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

const PaperStyle = styled((props) => <Paper elevation={5} {...props} />)(
  ({ theme }) => ({
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {}
  })
);
const PaperContentStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(9),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column'
}));

export default function FeatureList() {
  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ pt: 10, textAlign: 'center' }}>
        <Typography
          component="p"
          variant="overline"
          sx={{ mb: 2, color: 'text.secondary' }}
        >
          Engaged and Connected
        </Typography>
        <Typography variant="h2" sx={{ mb: 13 }}>
          Features that go beyond the classroom
        </Typography>
        <Grid container spacing={7}>
          {CARDS.map((item, index) => {
            return (
              <Grid key={index} item xs={12} md={4}>
                <PaperStyle>
                  <Avatar
                    sx={{ bgcolor: '#e0e9ff' }}
                    style={{
                      position: 'absolute',
                      left: '50%',
                      marginLeft: -35,
                      marginTop: -20,
                      height: 70,
                      width: 70
                    }}
                  >
                    {item.icon}
                  </Avatar>
                  <PaperContentStyle>
                    <Typography variant={'h5'}>{item.title}</Typography>
                    {item.description}
                  </PaperContentStyle>
                </PaperStyle>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </RootStyle>
  );
}
